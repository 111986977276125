import './about.scss';

import photo from './main_photo.webp';

const About = () => {
    return (
        <div className="container">
            <div className="about">
                <img src={photo} alt="" />
                <div className="about-content">
                    <h1>Hi, I'm Alex. I build user interfaces.</h1>
                    <span>I'm a passionate Frontend / React developer. I write clean, elegant and efficient code and I love what I do.</span>
                    <div className="about-skills">
                        <span><strong>Skills:</strong> JavaScript, React, Next.js, CSS, SCSS, Tailwind, HTML</span>
                        <span><strong>Second skills:</strong> PHP, NodeJS</span>
                        <span><strong>English:</strong> Conversational</span>
                    </div>
                    <span>I'm currently working full-time in Ukrainian Company.</span>
                </div>
            </div>
        </div>
    )
}

export default About;